@import "../../styles/variables.scss";

.rover-items {    
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 1rem;
	margin-bottom: 1rem;

}

.rover-item {
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem;
	border-color: $primary;
	border-style: solid;
	border-width: 0.1rem;
    flex-grow: 1;
}

.rover-item .rover-head {
	background-color: $light;
    text-align: center;
}

.rover-item .rover-head > a > img {
	width: 300px;
	height: 300px;
	object-fit: contain;
	object-position: top;
}

.rover-item .rover-body {
	background-color: $primary;
	padding: 0.5rem;
}

.rover-item .rover-body > p {
	margin: 0.5rem;
	color: $light;
}

.rover-title {
    color: $tertiary;
	font-size: 1.2rem;
	font-weight: bold;
	padding-bottom: 0.5rem;
}

.rover-row {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.rover-column {
     
	font-weight: bold;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}
