@import "../../styles/variables.scss";

div.container {
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	margin-top: 30px;	 
	padding: 21px;
    border-radius: 4px;    
}

 

svg {
    width: 24px;
    height: 24px;
}

div.omrs-input-group {
    margin-bottom: 1.5rem;
    position: relative;
    width: 20.4375rem;
  }
  
  /* Input*/
  .omrs-input-underlined > input,
  .omrs-input-filled > input {
      border: none;
      border-bottom: 0.125rem solid $primary;
      width: 100%;
      height: 2rem;
      font-size: 1.0625rem;
      padding-left: 0.875rem;
      line-height: 147.6%;
      padding-top: 0.825rem;
      padding-bottom: 0.5rem;
  }
  
  .omrs-input-underlined > input:focus,
  .omrs-input-filled > input:focus {
      outline: none;
  }
  
  .omrs-input-underlined > .omrs-input-label,
  .omrs-input-filled > .omrs-input-label {
      position: absolute;
      top: 0.9375rem;
      left: 0.875rem;
      line-height: 147.6%;
      color: $primary;
      transition: top .2s;
  }
  
  .omrs-input-underlined > svg,
  .omrs-input-filled > svg {
      position: absolute;
      top: 0.9375rem;
      right: 0.875rem;
      fill: $primary;
  }
  
  .omrs-input-underlined > .omrs-input-helper,
  .omrs-input-filled > .omrs-input-helper {
      font-size: 0.9375rem;
      color: $primary;
      letter-spacing: 0.0275rem;
      margin: 0.125rem 0.875rem;
  }
  
  .omrs-input-underlined > input:hover,
  .omrs-input-filled > input:hover {
      background: $tertiary;
      border-color: $primary;
  }
  
  .omrs-input-underlined > input:focus + .omrs-input-label,
  .omrs-input-underlined > input:valid + .omrs-input-label,
  .omrs-input-filled > input:focus + .omrs-input-label,
  .omrs-input-filled > input:valid + .omrs-input-label {
      top: 0;
      font-size: 0.9375rem;
      margin-bottom: 32px;;
  }
  
  .omrs-input-underlined:not(.omrs-input-danger) > input:focus + .omrs-input-label,
  .omrs-input-filled:not(.omrs-input-danger) > input:focus + .omrs-input-label {
      color: $primary;
  }
  
  .omrs-input-underlined:not(.omrs-input-danger) > input:focus,
  .omrs-input-filled:not(.omrs-input-danger) > input:focus {
      border-color: $primary;
  }
  
  .omrs-input-underlined:not(.omrs-input-danger) > input:focus ~ svg,
  .omrs-input-filled:not(.omrs-input-danger) > input:focus ~ svg {
      fill: $primary;
  }
  
  /** DISABLED **/
  
  .omrs-input-underlined > input:disabled {
      background: $primary;
      cursor: not-allowed;
  }
  
  .omrs-input-underlined > input:disabled + .omrs-input-label,
  .omrs-input-underlined > input:disabled ~ .omrs-input-helper{
      color: $primary;
  }
  
  .omrs-input-underlined > input:disabled ~ svg {
      fill: $primary;
  }
  
  
  /** DANGER **/
  
  .omrs-input-underlined.omrs-input-danger > .omrs-input-label, .omrs-input-underlined.omrs-input-danger > .omrs-input-helper,
  .omrs-input-filled.omrs-input-danger > .omrs-input-label, .omrs-input-filled.omrs-input-danger > .omrs-input-helper{
      color: $primary;
  }
  
  .omrs-input-danger > svg {
      fill: $primary;
  }
  
  .omrs-input-danger > input {
      border-color: $primary;
  }
  
  .omrs-input-underlined > input {
      background: $tertiary;
  }
  .omrs-input-filled > input {
      background: $primary;
  }