@import "../../styles/variables.scss";

ul.neolist {
	
}

.neo-card {
    margin-bottom: 1rem;
}

.neo-card .neo-title {
	background-color: $secundary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;    
    color: $tertiary;
	padding: 1rem;
}

.neo-card .neo-body {
	background-color: $tertiary;
    border: 1px $primary solid;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: $primary;
	padding: 1rem;
    line-height: 2rem;
}

.neo-body  a{
    color: $secundary;
}