@import "../../styles/variables.scss";

header {
    background-color: $primary;
    color: $superlight;
    ; 
}

header h1 {
    font-family: Georgia,serif;
    font-weight: bolder;
    font-size: 3rem;
    margin-left: 5rem;
    margin-top: 3rem;
}

header .subheading {
    font-family: Georgia,serif;
    font-weight: bold;
    font-size: 1.8rem;
    opacity: 0.75;
    margin-left: 5rem;
    margin-right: 5rem; 
    padding-bottom: 1rem;
    color: $superlight;
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {

    header h1 {
        margin-left: 1rem;
        margin-right: 0px;
        margin-top: 0px;         
        padding-top: 1rem;display: none;
    }

    header .subheading {
        margin-left: 1rem;
        margin-right: 0px;
        margin-top: 0px;;display: none;
    }
   
}

/* Smartphones (landscape) ----------- */
@media only screen 
and (min-width : 321px) {
     
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 320px) {
     
}

/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
     
}

/* iPads (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
     
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1224px) {
     
}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
     
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
     
}
