$primary: #3C6E71;
$secundary: #333;
$tertiary: #FFF;
$success: green;
$alert: #ff1900;
$light: #CCC;
$superlight: #FFF;
$dark: black;



