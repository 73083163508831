@import "./styles/variables.scss";

h2 {
	border-bottom: 2px solid $light;
	color: $primary;
	font-family: "Staatliches-Regular";
	font-size: 2.5rem;
	font-weight: 700;
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	text-align: right;	
}

p {
	line-height: 2rem;
	font-size: 1.2rem;
	color: $secundary;
}

.strong {
	font-weight: bolder;
}

.app {
	background-color: $tertiary;
	margin-top: 0em;
	padding-top: 2em;
	margin-left: 5em;
	margin-right: 5em;
}

form {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	font-size: 1.3rem;
	border-radius: 0.5rem;
}

form .row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

form > .row > label,
form .row > div > label,
form .row p {
	flex: 1 0 120px;
	max-width: 220px;
	color: $primary;
	padding-right: 1rem;
	padding-bottom: 1rem;
}

form > .row > label + *{
	flex: 1 0 220px;
}

form .row:not(:last-child) {
	margin-bottom: 1rem;
}

form .row input:not([type="checkbox"]),
form .row select,
form .row textarea {
	padding: 0.6rem;
	border: 0.1rem solid $light;
	background-color: $superlight;
	font-size: 1.3rem;
	color: $light;
}



form .row button {
	margin-left: auto;
	padding: 0.5rem 1rem;
	border: none;
	background: $primary;
	color: $superlight;
	letter-spacing: 0.09em;
	border-radius: 0.3rem;
}

form .row button:hover {
	cursor: pointer;
}

select, input[type="number"]{
    width: 10rem;
}

button {
	background: $primary;
	color: $superlight;
	letter-spacing: 0.09em;
	border: none;
	border-radius: 0.3rem;
	margin-left: auto;
	padding: 0.5rem 1rem;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	.app {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	form > .row > label {
		flex: 1 1 100%;
	}
	 
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
}

/* iPhone 4 ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
}
